<template>
  <CCardBody>
    <CButton
      color="primary"
      class="mb-2"
      :href="csvCode" 
      download="activelife.csv"
      target="_blank"
    >
      Download (.csv)
    </CButton>
    <CDataTable
      :items="customersData"
      :fields="fields"
      items-per-page-select
      :items-per-page="10"
      hover
      sorter
      pagination
      table-filter
      cleaner
      @filtered-items-change="setCurrentItems"
    >
    <!-- <template #fullname="{item}">
      <td>
        <router-link :to="{ name: 'customer', params: { id: item.id }}">{{item.fullname}}</router-link>
      </td>
    </template> -->
    <template #Action="{item}">
      <td class="py-2">
      <CButton size="sm" color="danger" @click="showDeleteModal(item)">
        Deactivate
      </CButton>
      </td>
    </template>
    
    </CDataTable>
    <CModal
      color="warning"
      title="Are you sure?"
      :show.sync="deleteModal"
    > 
      <div>
        <p class="font-weight-bold">Discontinue {{agent.name}}'s policy?</p>
      </div>
      
      <template #footer>
        <CButton @click="deleteModal = false" color="secondary">Cancel</CButton>
        <CButton  @click="deactivateAgent" color="danger">Proceed</CButton>
      </template>
    </CModal>
  </CCardBody>
</template>

<script>
import axios from 'axios'
import url from '@/main'

const fields = [
  { key: 'name', _style:'min-width:30%', label: 'Enrollee Name' },
  { key: 'phone', label: 'Phone number', _style:'min-width:20%;' },
  'email',
  {key: 'policy_number', label: 'Policy Number'},
  'Action'
  // {key:'is_verified', label: 'Status'},
]
const customersData = []
export default {
  data () {
    return {
      agent: '',
      fields,
      details: [],
      collapseDuration: 0,
      customersData: [],
      currentItems: customersData.slice(),
      deleteModal: false
    }
  },
  computed: {
    csvContent () {
      return this.currentItems.map(item => Object.values(item).join(',')).join('\n')
    },
    csvCode () {
      return 'data:text/csv;charset=utf-8,SEP=,%0A' + encodeURIComponent(this.csvContent)
    }
  },
  filters:{
    formateDate(str){
      var date = new Date(str);
      var day = date.getDate();
      var year = date.getFullYear();
      var month = date.getMonth()+1;
      var dateStr = year+"/"+month+"/"+day;
      return dateStr
    }
  },
  methods: {
    getBadge (status) {
      switch (status) {
        case true: return 'success'
        case false: return 'secondary'
      }
    },
    setCurrentItems (val) {
      this.currentItems = val
    },
    showDeleteModal(agent){
      this.agent = agent
      console.log(this.agent);
      this.deleteModal = true;
    },
    getAgents(){
      axios.get(`${url}/policy/life`)
      .then(res=>{
        this.$store.commit('stopLoading')
        // console.log(res)
        this.customersData = res.data.data.records
      })
      .catch(err=>{
        this.$store.commit('stopLoading')
        alert('Unable to load resources') 
      })
    },
    deactivateAgent(){
      this.deleteModal = false
      this.$store.commit('startLoading');
      let data = {"policy_id": this.agent.id}
      axios({url:`${url}/policy/life/discontinue`, data: data, method: 'POST'})
      .then(res=>{
        this.getAgents();
      })
      .catch(err=>{
        this.$store.commit('stopLoading');
        alert(`Unable to load resources: ${err}`)
      })
    }
  },

  beforeCreate(){
    this.$store.commit('startLoading');
    axios.get(`${url}/policy/life`)
    .then(res=>{
      this.$store.commit('stopLoading')
      // console.log(res)
      this.customersData = res.data.data.records
    })
    .catch(err=>{
      if(err.response){
        console.log(err.response)
        if(err.response.data.message == "Invalid or expired JWT"){
          this.$router.push('/app/login')
        }else{
          alert('Unable to load resources')
        }
      }
      this.$store.commit('stopLoading')
      // alert('Unable to load resources') 
    })
  }
}
</script>